import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
    en: {
        // #region Languages
        english: 'English',
        polish: 'Polski',
        ukrainian: 'Українська',
        german: 'Deutsch',
        french: 'Français',
        spanish: 'Español',
        // #endregion

        // #region Appbar
        projectName: 'Project:',
        downloadAsset: 'Download Asset',
        share: 'Share',
        switchToDarkMode: 'Switch to Dark Mode',
        switchToLightMode: 'Switch to Light Mode',
        subscribeToAsset: 'Subscribe to Asset',
        getNotifications: 'Get email notifications when',
        commentUpdates: 'Someone adds new comments to this asset',
        fileVersionsUpdates: 'Someone uploads a new version of this asset',
        // #endregion

        // #region Viewer
        generatingServiceFiles: 'Generating service files...',
        // #endregion

        // #region Viewer Tools
        annotate: 'Add Annotation',
        arrow: 'Arrow',
        point: 'Point',
        square: 'Square',
        circle: 'Circle',
        free: 'Free Form',
        pickColor: 'Pick Color',
        pickBackground: 'Pick Background',
        showAssets: 'Show Assets',
        hideAssetsPanel: 'Hide Assets Pane',
        hidePanel: 'Hide Tools Pane',
        showToolsPanel: 'Show Tools Pane',
        showAnnotations: 'Show Annotations',
        hideAnnotations: 'Hide Annotations',

        compareTools: 'Compare Tools',
        quitCompareMode: 'Quit Compare Mode',
        fitToViewport: 'Fit to viewport',
        zoomIn: 'Zoom in',
        zoomOut: 'Zoom out',
        rotateLeft: 'Rotate left',
        rotateRight: 'Rotate right',

        // #endregion

        // #region Approval
        confirmApprove: 'Approve',
        confirmApprovePrompt: 'Please confirm you want to approve',
        approve: 'Approve',
        approveSelected: 'Approve Selected',
        confirmApproveWithChanges: 'Approve with changes',
        approveWithChangesSelected: 'Approve with changes Selected',
        confirmApproveWithChangesPrompt: 'Please confirm you want to approve with changes',
        approveWithChanges: 'Approve with Changes',
        confirmRequestChanges: 'Request changes',
        confirmRequestChangesPrompt: 'Please confirm you want to request changes',
        requestChanges: 'Request Changes',
        checklistRequiredPrompt: 'Please check all the checklist items below',
        commentsAreRequired: 'Comments are required',
        youHaveToCommentAssetBeforeRequestingChanges: 'You have to enter at least one comment before requesting changes',
        ok: 'OK',
        cancel: 'Cancel',
        // #endregion

        // #region Textbox Menu
        annotationActions: 'Annotation Actions',
        editText: 'Edit text',
        revealInDiscussionPanel: 'Reveal in Discussion pane',
        cancelEditing: 'Cancel editing',
        addFigure: 'Add Figure',
        removeSelectedFigure: 'Remove Selected Figure',
        changeColor: 'Change Color',
        delete: 'Delete',
        confirmDeletingAnnotation: 'Confirm deleting annotation',
        deletingAnnotationPrompt: 'Are you sure you want to delete this annotation?',
        // #endregion

        // #region Thumbnails Panel
        version: 'version',
        loadPdfLayers: 'Load PDF layers',
        showPdfLayers: 'Show PDF layers',
        hidePdfLayers: 'Hide PDF layers',
        loadingPdfLayers: 'Loading PDF layers...',
        annotationsOnPage: 'Annotations on page',
        selectAll: 'Select all',
        // #endregion

        // #region Discussion Panel
        projectDiscussion: 'Project Discussion',
        assetDiscussion: 'Asset Annotations',
        showProjectDiscussion: 'Show project discussion',
        showAssetDiscussion: 'Show asset discussion',
        reply: 'Reply',
        showReplies: 'Show replies',
        hideReplies: 'Hide replies',
        showAllReplies: 'Show all replies',
        hideAllReplies: 'Hide all replies',
        send: 'Send',
        commentActions: 'Comment Actions',
        confirmDeletingComment: 'Confirm deleting comment',
        deletingCommentPrompt: 'Are you sure you want to delete this comment?',
        markComplete: 'Mark as complete',
        markIncomplete: 'Mark as incomplete',
        hideForExternal: 'Hide for external users',
        unhideForExternal: 'Unhide for external users',
        download: 'Download',
        attachFile: 'Attach file',
        deleteAttachment: 'Delete attachment',
        confirmDeletingAttachment: 'Confirm deleting attachment',
        deletingAttachmentPrompt: 'Are you sure you want to delete this attachment?',
        attachmentUploadError: 'An error occurred while uploading. Please try again later.',
        close: 'Close',
        save: 'Save',
        revealInViewer: 'Reveal in viewer',
        view: 'View',
        fileInfo: 'File Info',
        addReaction: 'Add a reaction',
        // #endregion

        // #region Compare Modes
        selectCompareAsset: 'Select asset to compare',
        generatingCompareResult: 'Generating Compare Result. This may take a while...',
        sideBySide: 'Side by Side',
        withDifference: 'Side by Side with Difference',
        fader: 'Fader',
        diff: 'Difference',
        toggle: 'Toggle',
        // #endregion

        // #region Extra Tools

        toolBox: 'Toolbox',
        ruler: 'Ruler',
        rulerUnits: 'Ruler units',
        setRulerStartingPoint: 'Set ruler starting point',
        rulerMultilineHint: 'Hold **Ctrl** to draw a multiline',
        distance: 'Distance',
        inches: 'Inches',
        centimeters: 'Centimeters',
        millimeters: 'Millimeters',
        eyedropper: 'Eyedropper',
        colorModel: 'Color model',
        colorValue: 'Color value',
        pickColorFromAsset: 'Pick color from asset',
        generateColors: 'Generate color scheme',
        processing: 'Processing...',
        barcodeScanner: 'Barcode Scanner',
        barcodeScannerPrompt: 'Select region that contains a barcode',
        barcodePanningPrompt: 'Drag the image to find a barcode',
        toPanMode: 'Toggle to move the image',
        toScanMode: 'Toggle to scan another barcode',
        readingBarcode: 'Reading barcode...',
        barcodeType: 'Barcode type:',
        text: 'Text:',
        spellchecker: 'Spellchecker',
        spellcheckerServiceError: 'We couldn\'t connect to the spellchecker service. Please try again later.',
        retry: 'Retry',
        'Barcode not recognized.': 'We couldn\'t recognize barcode in the selected region. Try to zoom image or select more accurately',
        barcodeServerError: 'An error occurred while processing barcode. Please try again later.',
        markWordAsCorrect: 'Mark word as correct',
        markWordAsIncorrect: 'Mark word as incorrect',
        wordMarkedAsCorrect: 'Word "{0}" was marked as correct',
        wordMarkedAsIncorrect: 'Word "{0}" was marked as incorrect',
        talkToAi: 'Talk to AI',
        enterYourQuestion: 'Enter your question',
        aiAssistantError: 'An error occurred while processing your request. Please try again later.',
        // #endregion

        // #region Alerts
        taskWasClosed: 'The task was closed',
        assetFilesCorrupted: 'Some of the asset files are corrupted. Please contact your administrator.',
        // #endregion

        // #region Share Dialog
        createExternalReview: 'Create External Review',
        email: 'Email',
        name: 'Name',
        addressBookRecord: 'Address Book Record',
        editAddressBookRecord: 'Edit Address Book Record',
        saveAddress: 'Save Address',
        remove: 'Remove',
        advancedOptions: 'Advanced options',
        hideAdvancedOptions: 'Hide',
        allowAssetDownload: 'Allow Asset Download',
        simpleMode: 'Simple Mode',
        allowSubscriptions: 'Allow to subscribe to asset changes',
        subject: 'Subject',
        password: 'Password',
        comment: 'Comment',
        checklist: 'Checklist',
        selectChecklist: 'Select a Checklist',
        configure: 'Configure',
        getShortlink: 'Get a short link',
        checklistConfigurePrompt: 'Pick items to include in the review checklist',
        customized: '(customized)',
        apply: 'Apply',
        emailsWereSent: 'Request email(s) were sent successfully',
        shortlinkCopied: 'Shortlink was copied to the clipboard',
        addressBook: 'Address Book',
        selectContacts: 'Select contacts to include to your mailing list',
        selectContactsAndGroups: 'Select contacts and groups to include to your mailing list',
        contacts: 'Contacts',
        groups: 'Groups',
        newConact: 'New Contact',
        EditGroup: 'Edit Group',
        selectiveApprovalMode: 'Use Selective Approval Mode',
        template: 'Template',
        // #endregion

        // #region External User Intro Dialog
        introduceYourself: 'Please introduce yourself',
        enterYourName: 'Enter your name',
        confirm: 'Confirm',
        // #endregion

        thisLinkIsWrong: 'This link is wrong or outdated!',
        goHome: 'Go home',
        approvalStudio: 'Approval Studio',
        copyright: 'Copyright © 2018-2025 by Approval Studio',
        enterYourComment: 'Enter your comment',
        dueDate: 'Due Date',
        failedGenerateColorScheme: 'Failed to generate color scheme',
        failedGetPixelInfo: 'Failed to get pixel info',
        exifInfo: 'EXIF Info',

        // #region Tour
        takeTour: 'Take a tour',
        tourInternalIntro: 'Welcome to the Approval Studio review tool! Take a short tour to see how it works',
        tourExternalIntro: 'You\'ve been invited to review a file. Take a short tour to see how it works',
        tourZoom: 'You can zoom in, zoom out or rotate the artwork, to ensure you check everything',
        tourApprove: 'Hit the Approve button if you\'re happy with what you see and nothing needs to be changed',
        tourAnnotate: 'If something needs to be changed or commented on, select an annotation tool and draw on the area of the image you want to annotate.',
        tourRequestChanges: 'Hit the Request Changes button to request changes and send us your feedback',
        tourCompare: 'Choose from one of the comparison modes to see the differences between the current and previous versions of the file',
        tourTools: 'Use our extra tools to measure distances, pick colors, scan barcodes, and more',
        tourProjectDiscussion: 'Use the Project Discussion panel to discuss the project with your team',
        tourDownload: 'Download the file if you need to keep it on your device',
        tourShare: 'Share the file with your colleagues or clients by email or by shared link',
        // #endregion

        featureDisabledInDemoMode: 'This feature is disabled in demo mode',
    },
    de: {
        // #region Languages
        english: 'English',
        polish: 'Polski',
        ukrainian: 'Українська',
        german: 'Deutsch',
        french: 'Français',
        spanish: 'Español',
        // #endregion

        // #region Appbar
        projectName: 'Projekt:',
        downloadAsset: 'Posten herunterladen',
        share: 'Teilen',
        switchToDarkMode: 'Zum dunklen Modus wechseln',
        switchToLightMode: 'Zum hellen Modus wechseln',
        subscribeToAsset: 'Datei abonnieren',
        getNotifications: 'E-Mail-Benachrichtigungen erhalten, wenn',
        commentUpdates: 'Jemand neue Kommentare zu dieser Datei hinzufügt',
        fileVersionsUpdates: 'Jemand eine neue Version dieser Datei hochlädt',
        // #endregion

        // #region Viewer
        generatingServiceFiles: 'Dienstdateien werden generiert...',
        // #endregion

        // #region Viewer Tools
        annotate: 'Anmerkung hinzufügen',
        arrow: 'Pfeil',
        point: 'Punkt',
        square: 'Quadrat',
        circle: 'Kreis',
        free: 'Freie Form',
        pickColor: 'Farbe wählen',
        pickBackground: 'Hintergrund auswählen',
        showAssets: 'Posten anzeigen',
        hideAssetsPanel: 'Posten-Panel ausblenden',
        hidePanel: 'Werkzeug-Panel ausblenden',
        showToolsPanel: 'Werkzeug-Panel anzeigen',
        showAnnotations: 'Anmerkungen anzeigen',
        hideAnnotations: 'Anmerkungen ausblenden',

        compareTools: 'Vergleichstools',
        quitCompareMode: 'Vergleichsmodus beenden',
        fitToViewport: 'An den Bildschirm anpassen',
        zoomIn: 'Vergrößern',
        zoomOut: 'Verkleinern',
        rotateLeft: 'Nach links drehen',
        rotateRight: 'Nach rechts drehen',

        // #endregion

        // #region Approval
        confirmApprove: 'Genehmigen',
        confirmApprovePrompt: 'Bitte bestätigen Sie, dass Sie genehmigen möchten',
        approve: 'Genehmigen',
        approveSelected: 'Ausgewähltes genehmigen',
        confirmApproveWithChanges: 'Mit Änderungen genehmigen',
        approveWithChangesSelected: 'Mit Änderungen ausgewähltes genehmigen',
        confirmApproveWithChangesPrompt: 'Bitte bestätigen Sie, dass Sie mit Änderungen genehmigen möchten',
        approveWithChanges: 'Mit Änderungen genehmigen',
        confirmRequestChanges: 'Änderungen anfordern',
        confirmRequestChangesPrompt: 'Bitte bestätigen Sie, dass Sie die Änderungen anfordern möchten',
        requestChanges: 'Änderungen anfordern',
        checklistRequiredPrompt: 'Bitte überprüfen Sie alle Elemente auf der Checkliste unten',
        commentsAreRequired: 'Kommentare sind erforderlich',
        youHaveToCommentAssetBeforeRequestingChanges: 'Sie müssen mindestens einen Kommentar eingeben, bevor Sie Änderungen anfordern können',
        ok: 'OK',
        cancel: 'Abbrechen',
        // #endregion

        // #region Textbox Menu
        annotationActions: 'Aktionen für Anmerkungen',
        editText: 'Text bearbeiten',
        revealInDiscussionPanel: 'Im Diskussionsbereich anzeigen',
        cancelEditing: 'Bearbeitung abbrechen',
        addFigure: 'Figur hinzufügen',
        removeSelectedFigure: 'Ausgewählte Figur entfernen',
        changeColor: 'Farbe ändern',
        delete: 'Löschen',
        confirmDeletingAnnotation: 'Löschen der Anmerkung bestätigen',
        deletingAnnotationPrompt: 'Sind Sie sicher, dass Sie diese Anmekrung löschen möchten?',
        // #endregion

        // #region Thumbnails Panel
        version: 'version',
        loadPdfLayers: 'Laden Sie PDF-Ebenen',
        showPdfLayers: 'PDF-Ebenen anzeigen',
        hidePdfLayers: 'PDF-Ebenen ausblenden',
        loadingPdfLayers: 'Laden von PDF-Ebenen...',
        annotationsOnPage: 'Anmerkungen auf Seite',
        selectAll: 'Alles auswählen',
        // #endregion

        // #region Discussion Panel
        projectDiscussion: 'Projektdiskussion',
        assetDiscussion: 'Posten-Anmerkungen',
        showProjectDiscussion: 'Projektdiskussion anzeigen',
        showAssetDiscussion: 'Posten-Diskussion anzeigen',
        reply: 'Antworten',
        showReplies: 'Antworten anzeigen',
        hideReplies: 'Antworten ausblenden',
        showAllReplies: 'Alle Antworten anzeigen',
        hideAllReplies: 'Alle Antworten ausblenden',
        send: 'Senden',
        commentActions: 'Aktionen für Kommentare',
        confirmDeletingComment: 'Löschen des Kommentars bestätigen',
        deletingCommentPrompt: 'Sind Sie sicher, dass Sie diesen Kommentar löschen möchten?',
        markComplete: 'Als abgeschlossen markieren',
        markIncomplete: 'Als nicht abgeschlossen markieren',
        hideForExternal: 'Für externe Benutzer ausblenden',
        unhideForExternal: 'Für externe Benutzer einblenden',
        download: 'Herunterladen',
        attachFile: 'Datei anhängen',
        deleteAttachment: 'Anhang löschen',
        confirmDeletingAttachment: 'Löschen des Anhangs bestätigen',
        deletingAttachmentPrompt: 'Sind Sie sicher, dass Sie diesen Anhang löschen möchten?',
        attachmentUploadError: 'Fehler beim Hochladen. Bitte versuchen Sie es später erneut.',
        close: 'Schließen',
        save: 'Speichern',
        revealInViewer: 'Im Überprüfungstool anzeigen',
        view: 'Anzeigen',
        fileInfo: 'Dateiinfo',
        addReaction: 'Reaktion hinzufügen',
        // #endregion

        // #region Compare Modes
        selectCompareAsset: 'Zu vergleichendes Posten auswählen',
        generatingCompareResult: 'Vergleichsergebnis wird generiert. Dies kann eine Weile dauern...',
        sideBySide: 'Nebeneinander',
        withDifference: 'Nebeneinander mit Unterschieden',
        fader: 'Fader',
        diff: 'Differenz',
        toggle: 'Umschalten',
        // #endregion

        // #region Extra Tools

        toolBox: 'Toolbox',
        ruler: 'Lineal',
        rulerUnits: 'Linealeinheiten',
        setRulerStartingPoint: 'Linealnullpunkt festlegen',
        rulerMultilineHint: 'Halten Sie die **Strg**-Taste gedrückt, um mehrere Linien zu zeichnen',
        distance: 'Abstand',
        inches: 'Zoll',
        centimeters: 'Zentimeter',
        millimeters: 'Millimeter',
        eyedropper: 'Pipette',
        colorModel: 'Farbmodell',
        colorValue: 'Farbwert',
        pickColorFromAsset: 'Farbe aus Posten wählen',
        generateColors: 'Farbschema generieren',
        processing: 'Verarbeitung...',
        barcodeScanner: 'Barcodeleser',
        barcodeScannerPrompt: 'Wählen Sie den Bereich aus, der einen Barcode enthält',
        barcodePanningPrompt: 'Ziehen Sie das Bild, um einen Barcode zu finden',
        toPanMode: 'Umschalten, um das Bild zu verschieben',
        toScanMode: 'Umschalten, um einen anderen Barcode zu lesen',
        readingBarcode: 'Strichcode lesen...',
        barcodeType: 'Barcodetyp:',
        text: 'Text:',
        spellchecker: 'Rechtschreibprüfung',
        spellcheckerServiceError: 'Wir konnten keine Verbindung zum Rechtschreibprüfungs-Service herstellen. Bitte versuchen Sie es später erneut.',
        retry: 'Erneut versuchen',
        'Barcode not recognized.': 'Wir konnten den Barcode im ausgewählten Bereich nicht erkennen. Versuchen Sie, das Bild zu zoomen oder genauer zu wählen',
        barcodeServerError: 'Beim Verarbeiten des Barcodes ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
        markWordAsCorrect: 'Wort als korrekt markieren',
        markWordAsIncorrect: 'Wort als inkorrekt markieren',
        wordMarkedAsCorrect: 'Das Wort "{0}" wurde als korrekt markiert',
        wordMarkedAsIncorrect: 'Das Wort "{0}" wurde als inkorrekt markiert',
        talkToAi: 'Mit KI sprechen',
        enterYourQuestion: 'Geben Sie Ihre Frage ein',
        aiAssistantError: 'Beim Verarbeiten Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
        // #endregion

        // #region Alerts
        taskWasClosed: 'Die Aufgabe wurde geschlossen',
        assetFilesCorrupted: 'Einige der Posten-Dateien sind beschädigt. Bitte wenden Sie sich an Ihren Administrator.',
        // #endregion

        // #region Share Dialog
        createExternalReview: 'Externe Überprüfung erstellen',
        Name: 'Name',
        email: 'E-Mails durch Kommas getrennt',
        emailError: 'E-Mails sind ungültig',
        addressBookRecord: 'Adressbuch-Eintrag',
        editAddressBookRecord: 'Adressbuch-Eintrag bearbeiten',
        saveAddress: 'Adresse speichern',
        remove: 'Entfernen',
        advancedOptions: 'Erweiterte Optionen',
        hideAdvancedOptions: 'Ausblenden',
        allowAssetDownload: 'Posten-Herunterladen Zulassen',
        allowSubscriptions: 'Erlauben, Änderungen an der Datei zu abonnieren',
        simpleMode: 'Einfacher Modus',
        subject: 'Betreff',
        password: 'Kennwort',
        comment: 'Kommentieren',
        checklist: 'Prüfliste',
        selectChecklist: 'Prüfliste auswählen',
        configure: 'Konfigurieren',
        getShortlink: 'Kurzlink erhalten',
        checklistConfigurePrompt: 'Wählen Sie Elemente aus, die in der Überprüfungsliste enthalten sein sollen',
        customized: '(angepasst)',
        apply: 'Anwenden',
        emailsWereSent: 'Anfrage-E-Mails wurden erfolgreich versandt',
        shortlinkCopied: 'Der Kurzlink wurde in die Zwischenablage kopiert',
        addressBook: 'Adressbuch',
        selectContacts: 'Wählen Sie Kontakte aus, die in Ihre Mailingliste aufgenommen werden sollen',
        selectContactsAndGroups: 'Wählen Sie Kontakte und Gruppen aus, die in Ihre Mailingliste aufgenommen werden sollen',
        contacts: 'Kontakte',
        groups: 'Gruppen',
        newConact: 'Neuer Kontakt',
        EditGroup: 'Gruppe bearbeiten',
        selectiveApprovalMode: 'Selektiver Genehmigungsmodus',
        template: 'Vorlage',
        // #endregion

        // #region External User Intro Dialog
        introduceYourself: 'Bitte stellen Sie sich vor',
        enterYourName: 'Geben Sie Ihren Namen ein',
        confirm: 'Bestätigen',
        // #endregion

        copyright: 'Copyright © 2018-2025 by Approval Studio',
        dueDate: 'Fälligkeitsdatum',

        thisLinkIsWrong: 'Dieser Link ist falsch oder veraltet!',
        goHome: 'Zurück zur Startseite',
        approvalStudio: 'Approval Studio',
        enterYourComment: 'Geben Sie Ihren Kommentar ein',
        failedGenerateColorScheme: 'Farbschema konnte nicht generiert werden',
        failedGetPixelInfo: 'Fehler beim Abrufen von Pixelinformationen',
        exifInfo: 'EXIF Info',

        // #region Tour
        takeTour: 'Tour starten',
        tourInternalIntro: 'Willkommen beim Approval Studio Überprüfungstool! Machen Sie eine kurze Tour, um zu sehen, wie es funktioniert',
        tourExternalIntro: 'Sie wurden eingeladen, eine Datei zu überprüfen. Machen Sie eine kurze Tour, um zu sehen, wie es funktioniert',
        tourZoom: 'Sie können die Ansicht vergrößern, verkleinern oder das Bild drehen, um sicherzustellen, dass Sie alles überprüfen',
        tourApprove: 'Klicken Sie auf die Schaltfläche "Genehmigen", wenn Sie zufrieden sind und nichts geändert werden muss',
        tourAnnotate: 'Wenn etwas geändert oder kommentiert werden muss, wählen Sie ein Anmerkungswerkzeug aus und markieren Sie den Bereich des Bildes, den Sie kommentieren möchten.',
        tourRequestChanges: 'Klicken Sie auf die Schaltfläche "Änderungen anfordern", um Änderungen anzufordern und uns Ihr Feedback zu senden',
        tourCompare: 'Wählen Sie einen der Vergleichsmodi, um die Unterschiede zwischen der aktuellen und der vorherigen Version der Datei zu sehen',
        tourTools: 'Nutzen Sie unsere zusätzlichen Werkzeuge, um Entfernungen zu messen, Farben auszuwählen, Barcodes zu scannen und mehr',
        tourProjectDiscussion: 'Verwenden Sie das Projekt-Diskussionspanel, um das Projekt mit Ihrem Team zu besprechen',
        tourDownload: 'Laden Sie die Datei herunter, wenn Sie sie auf Ihrem Gerät speichern möchten',
        tourShare: 'Teilen Sie die Datei mit Ihren Kollegen oder Kunden per E-Mail oder über einen freigegebenen Link',
        // #endregion

        featureDisabledInDemoMode: 'Diese Funktion ist im Demo-Modus deaktiviert',
    },
    es: {
        // #region Languages
        english: 'English',
        polish: 'Polski',
        ukrainian: 'Українська',
        german: 'Deutsch',
        french: 'Français',
        spanish: 'Español',
        // #endregion

        // #region Appbar
        projectName: 'Proyecto:',
        downloadAsset: 'Descargar el recurso',
        share: 'Compartir',
        switchToDarkMode: 'Cambiar a Modo Oscuro',
        switchToLightMode: 'Cambiar a Modo Claro',
        subscribeToAsset: 'Suscribirse al archivo',
        getNotifications: 'Recibir notificaciones por correo electrónico cuando',
        commentUpdates: 'Alguien agregue nuevos comentarios a este archivo',
        fileVersionsUpdates: 'Alguien suba una nueva versión de este archivo',
        // #endregion

        // #region Viewer
        generatingServiceFiles: 'Los archivos de servicio se están generando...',
        // #endregion

        // #region Viewer Tools
        annotate: 'Agregar Anotación',
        arrow: 'Flecha',
        point: 'Punto',
        square: 'Cuadrado',
        circle: 'Círculo',
        free: 'Forma Libre',
        pickColor: 'Elegir el color',
        pickBackground: 'Seleccionar Fondo',
        showAssets: 'Mostrar Activos',
        hideAssetsPanel: 'Ocultar Panel de Activos',
        hidePanel: 'Ocultar Panel de Herramientas',
        showToolsPanel: 'Mostrar Panel de Herramientas',
        showAnnotations: 'Mostrar Anotaciones',
        hideAnnotations: 'Ocultar Anotaciones',

        compareTools: 'Herramientas de Comparación',
        quitCompareMode: 'Salir del Modo de Comparación',
        fitToViewport: 'Ajustar al área de visualización',
        zoomIn: 'Acercar',
        zoomOut: 'Alejar',
        rotateLeft: 'Girar a la izquierda',
        rotateRight: 'Girar a la derecha',

        // #endregion

        // #region Approval
        confirmApprove: 'Aprobar',
        confirmApprovePrompt: 'Confirme que quiere aprobar',
        approve: 'Aprobar',
        approveSelected: 'Aprobar lo Seleccionado',
        confirmApproveWithChanges: 'Aprobar con cambios',
        approveWithChangesSelected: 'Aprobar con Cambios Seleccionados',
        confirmApproveWithChangesPrompt: 'Confirme que desea aprobar con cambios',
        approveWithChanges: 'Aprobar con cambios',
        confirmRequestChanges: 'Solicitar cambios',
        confirmRequestChangesPrompt: 'Confirme que quiere solicitar cambios',
        requestChanges: 'Solicitar cambios',
        checklistRequiredPrompt: 'Por favor, marque todos los elementos de la lista de verificación a continuación',
        commentsAreRequired: 'Se requieren comentarios',
        youHaveToCommentAssetBeforeRequestingChanges: 'Debe insertar al menos un comentario antes de solicitar cambios',
        ok: 'OK',
        cancel: 'Cancelar',
        // #endregion

        // #region Textbox Menu
        annotationActions: 'Acciones de Anotación',
        editText: 'Editar el texto',
        revealInDiscussionPanel: 'Revelar en panel de discusión',
        cancelEditing: 'Cancelar la edición',
        addFigure: 'Añadir Figura',
        removeSelectedFigure: 'Eliminar Figura Seleccionada',
        changeColor: 'Cambiar el Color',
        delete: 'Quitar',
        confirmDeletingAnnotation: 'Confirmar eliminación de la anotación',
        deletingAnnotationPrompt: '¿Está seguro de que desea eliminar esta anotación?',
        // #endregion

        // #region Thumbnails Panel
        version: 'version',
        loadPdfLayers: 'Cargar capas de PDF',
        showPdfLayers: 'Mostrar capas de PDF',
        hidePdfLayers: 'Ocultar capas de PDF',
        loadingPdfLayers: 'Cargando capas de PDF...',
        annotationsOnPage: 'Anotaciones en la página',
        selectAll: 'Seleccionar todo',
        // #endregion

        // #region Discussion Panel
        projectDiscussion: 'Discusión del proyecto',
        assetDiscussion: 'Anotaciones de Activos',
        showProjectDiscussion: 'Mostrar discusión del proyecto',
        showAssetDiscussion: 'Mostrar discusión del activo',
        reply: 'Contestar',
        showReplies: 'Mostrar respuestas',
        hideReplies: 'Ocultar respuestas',
        showAllReplies: 'Mostrar todas las respuestas',
        hideAllReplies: 'Ocultar todas las respuestas',
        send: 'Enviar',
        commentActions: 'Acciones de Comentario',
        confirmDeletingComment: 'Confirmar eliminación del comentario',
        deletingCommentPrompt: '¿Está seguro de que desea eliminar este comentario?',
        markComplete: 'Marcar como completado',
        markIncomplete: 'Marcar como no completado',
        hideForExternal: 'Ocultar para usuarios externos',
        unhideForExternal: 'Mostrar para usuarios externos',
        download: 'Descargar',
        attachFile: 'Adjuntar el archivo',
        deleteAttachment: 'Eliminar adjunto',
        confirmDeletingAttachment: 'Confirmar la eliminación del archivo adjunto',
        deletingAttachmentPrompt: '¿Está seguro de que desea eliminar este adjunto?',
        attachmentUploadError: 'Ocurrió un error al cargar. Por favor, intente nuevamente más tarde.',
        close: 'Cerrar',
        save: 'Guardar',
        revealInViewer: 'Revelar en visor',
        view: 'Ver',
        fileInfo: 'Información del archivo',
        addReaction: 'Añadir una reacción',
        // #endregion

        // #region Compare Modes
        selectCompareAsset: 'Seleccionar activo para comparar',
        generatingCompareResult: 'Generando Resultado de Comparación. Esto puede demorar ...',
        sideBySide: 'Al lado',
        withDifference: 'Lado a Lado con Diferencia',
        fader: 'Fader',
        diff: 'Diferencia',
        toggle: 'Alternar',
        // #endregion

        // #region Extra Tools

        toolBox: 'Caja de herramientas',
        ruler: 'Regla',
        rulerUnits: 'Unidades de regla',
        setRulerStartingPoint: 'Establecer el punto inicial de la regla',
        rulerMultilineHint: 'Mantenga presionada la tecla **Ctrl** para dibujar una línea múltiple',
        distance: 'Distancia',
        inches: 'Pulgadas',
        centimeters: 'Centímetros',
        millimeters: 'Milímetros',
        eyedropper: 'Cuentagotas',
        colorModel: 'Modelo de color',
        colorValue: 'Valor del color',
        pickColorFromAsset: 'Seleccionar color desde el activo',
        generateColors: 'Generar esquema de colores',
        processing: 'Procesando...',
        barcodeScanner: 'Escáner de Códigos de Barras',
        barcodeScannerPrompt: 'Seleccione la región que contiene un código de barras',
        barcodePanningPrompt: 'Arrastre la imagen para encontrar un código de barras',
        toPanMode: 'Cambiar al modo de movimiento de la imagen',
        toScanMode: 'Cambiar al modo de escaneo de otro código de barras',
        readingBarcode: 'Leyendo código de barras...',
        barcodeType: 'Tipo de código de barras:',
        text: 'Texto:',
        spellchecker: 'Corrector ortográfico',
        spellcheckerServiceError: 'No pudimos conectar con el servicio de corrección ortográfica. Por favor, intente nuevamente más tarde.',
        retry: 'Reintentar',
        'Barcode not recognized.': 'No pudimos reconocer el código de barras en la región seleccionada. Intente hacer zoom en la imagen o seleccionar con mayor precisión',
        barcodeServerError: 'Hubo un error al procesar el código de barras. Por favor, intente nuevamente más tarde.',
        markWordAsCorrect: 'Marcar palabra como correcta',
        markWordAsIncorrect: 'Marcar palabra como incorrecta',
        wordMarkedAsCorrect: 'La palabra "{0}" fue marcada como correcta',
        wordMarkedAsIncorrect: 'La palabra "{0}" fue marcada como incorrecta',
        talkToAi: 'Habla con IA',
        enterYourQuestion: 'Ingrese su pregunta',
        aiAssistantError: 'Se ha producido un error al procesar su solicitud. Por favor, inténtelo de nuevo más tarde.',
        // #endregion

        // #region Alerts
        taskWasClosed: 'La tarea fue cerrada',
        assetFilesCorrupted: 'Algunos archivos de activos están corruptos. Por favor, contacte con su administrador.',
        // #endregion

        // #region Share Dialog
        createExternalReview: 'Enviar para la revisión',
        Name: 'Nombre',
        email: 'Correos electrónicos separados por comas',
        emailError: 'Los correos electrónicos no son válidos',
        addressBookRecord: 'Registro de la libreta de direcciones',
        editAddressBookRecord: 'Editar registro de la libreta de direcciones',
        saveAddress: 'Enregistrer l\'adresse',
        remove: 'Eliminar',
        advancedOptions: 'Opciones avanzadas',
        hideAdvancedOptions: 'Ocultar',
        allowAssetDownload: 'Permitir descargar el recurso',
        allowSubscriptions: 'Permitir suscribirse a cambios del archivo',
        simpleMode: 'Modo simple',
        subject: 'Asunto del email',
        password: 'Contraseña',
        comment: 'Comentario',
        checklist: 'Lista de verificación',
        selectChecklist: 'Seleccionar una lista de verificación',
        configure: 'Configurar',
        getShortlink: 'Obtener un enlace corto',
        checklistConfigurePrompt: 'Seleccione elementos para incluir en la lista de verificación de revisión',
        customized: '(personalizado)',
        apply: 'Aplicar',
        emailsWereSent: 'Los correos electrónicos de solicitud fueron enviados exitosamente',
        shortlinkCopied: 'El enlace corto fue copiado al portapapeles',
        addressBook: 'Libreta de direcciones',
        selectContacts: 'Seleccionar contactos para incluir en tu lista de correo',
        selectContactsAndGroups: 'Seleccionar contactos y grupos para incluir en tu lista de correo',
        contacts: 'Contactos',
        groups: 'Grupos',
        newConact: 'Nuevo contacto',
        EditGroup: 'Editar grupo',
        selectiveApprovalMode: 'Modo selectivo de aprobación',
        template: 'Plantilla',
        // #endregion

        // #region External User Intro Dialog
        introduceYourself: 'Por favor, preséntese',
        enterYourName: 'Ingrese su nombre',
        confirm: 'Confirmar',
        // #endregion

        copyright: 'Copyright © 2018-2025 by Approval Studio',
        dueDate: 'Completar antes de',

        thisLinkIsWrong: '¡Este enlace es incorrecto o está desactualizado!',
        goHome: 'Ir a la página de inicio',
        approvalStudio: 'Approval Studio',
        enterYourComment: 'Ingrese su comentario',
        failedGenerateColorScheme: 'Error al generar el esquema de colores',
        failedGetPixelInfo: 'Error al obtener información del píxel',
        exifInfo: 'EXIF Info',

        // #region Tour
        takeTour: 'Hacer un recorrido',
        tourInternalIntro: '¡Bienvenido a la herramienta de revisión de Approval Studio! Haz un breve recorrido para ver cómo funciona',
        tourExternalIntro: 'Has sido invitado a revisar un archivo. Haz un breve recorrido para ver cómo funciona',
        tourZoom: 'Puedes acercar, alejar o rotar la imagen para asegurarte de verificar todo',
        tourApprove: 'Haz clic en el botón "Aprobar" si estás satisfecho y no necesitas realizar cambios',
        tourAnnotate: 'Si algo necesita ser cambiado o comentado, selecciona una herramienta de anotación y marca el área de la imagen que deseas anotar.',
        tourRequestChanges: 'Haz clic en el botón "Solicitar cambios" para pedir modificaciones y enviarnos tus comentarios',
        tourCompare: 'Elige uno de los modos de comparación para ver las diferencias entre la versión actual y la anterior del archivo',
        tourTools: 'Utiliza nuestras herramientas adicionales para medir distancias, seleccionar colores, escanear códigos de barras y más',
        tourProjectDiscussion: 'Usa el panel de Discusión del Proyecto para discutir el proyecto con tu equipo',
        tourDownload: 'Descarga el archivo si necesitas guardarlo en tu dispositivo',
        tourShare: 'Comparte el archivo con tus colegas o clientes por correo electrónico o enlace compartido',
        // #endregion

        featureDisabledInDemoMode: 'Esta función está deshabilitada en modo de demostración',
    },
    fr: {
        // #region Languages
        english: 'English',
        polish: 'Polski',
        ukrainian: 'Українська',
        german: 'Deutsch',
        french: 'Français',
        spanish: 'Español',
        // #endregion

        // #region Appbar
        projectName: 'Projet:',
        downloadAsset: 'Télécharger le composant',
        share: 'Partager',
        switchToDarkMode: 'Passer au mode sombre',
        switchToLightMode: 'Passer au mode clair',
        subscribeToAsset: 'S\'abonner au fichier',
        getNotifications: 'Recevoir des notifications par e-mail lorsque',
        commentUpdates: 'Quelqu\'un ajoute de nouveaux commentaires à ce fichier',
        fileVersionsUpdates: 'Quelqu\'un télécharge une nouvelle version de ce fichier',
        // #endregion

        // #region Viewer
        generatingServiceFiles: 'Nous générons des fichiers de service...',
        // #endregion

        // #region Viewer Tools
        annotate: 'Ajouter une annotation',
        arrow: 'Flèche',
        point: 'Point',
        square: 'Carré',
        circle: 'Cercle',
        free: 'Forme libre',
        pickColor: 'Choisissez la couleur',
        pickBackground: 'Choisir l\'arrière-plan',
        showAssets: 'Afficher les ressources',
        hideAssetsPanel: 'Masquer le volet des ressources',
        hidePanel: 'Masquer le volet des outils',
        showToolsPanel: 'Afficher le volet des outils',
        showAnnotations: 'Afficher les annotations',
        hideAnnotations: 'Masquer les annotations',

        compareTools: 'Outils de comparaison',
        quitCompareMode: 'Quitter le mode de comparaison',
        fitToViewport: 'Ajuster à la fenêtre d\'affichage',
        zoomIn: 'Agrandir',
        zoomOut: 'Dézoomer',
        rotateLeft: 'Pivoter à gauche',
        rotateRight: 'Pivoter à droite',

        // #endregion

        // #region Approval
        confirmApprove: 'Approuver',
        confirmApprovePrompt: 'S`il vous plaît, confirmez que vous voulez approuver',
        approve: 'Approuver',
        approveSelected: 'Approuver la sélection',
        confirmApproveWithChanges: 'Approuver avec modifications',
        approveWithChangesSelected: 'Approuver avec modifications sélectionnées',
        confirmApproveWithChangesPrompt: 'S`il vous plaît, confirmez que vous souhaitez approuver le fichier avec des modifications',
        approveWithChanges: 'Approuver avec modifications',
        confirmRequestChanges: 'Demander des modifications',
        confirmRequestChangesPrompt: 'S`il vous plaît, confirmez que vous souhaitez demander des modifications',
        requestChanges: 'Demander des modifications',
        checklistRequiredPrompt: 'Veuillez cocher tous les éléments de la liste de contrôle ci-dessous',
        commentsAreRequired: 'Des commentaires sont requis',
        youHaveToCommentAssetBeforeRequestingChanges: 'Vous devez saisir au moins un commentaire avant de demander des modifications',
        ok: 'OK',
        cancel: 'Annuler',
        // #endregion

        // #region Textbox Menu
        annotationActions: 'Actions d\'annotation',
        editText: 'Modifier le texte',
        revealInDiscussionPanel: 'Révéler dans le volet de discussion',
        cancelEditing: 'Annuler la modification',
        addFigure: 'Ajouter une figure',
        removeSelectedFigure: 'Supprimer la figure sélectionnée',
        changeColor: 'Changer la couleur',
        delete: 'Supprimer',
        confirmDeletingAnnotation: 'Confirmer la suppression de l\'annotation',
        deletingAnnotationPrompt: 'Êtes-vous sûr de vouloir supprimer cette annotation ?',
        // #endregion

        // #region Thumbnails Panel
        version: 'version',
        loadPdfLayers: 'Charger des calques PDF',
        showPdfLayers: 'Afficher les calques PDF',
        hidePdfLayers: 'Masquer les calques PDF',
        loadingPdfLayers: 'Chargement de calques PDF...',
        annotationsOnPage: 'Annotations sur la page',
        selectAll: 'Sélectionner tout',
        // #endregion

        // #region Discussion Panel
        projectDiscussion: 'Discussion sur le projet',
        assetDiscussion: 'Annotations de ressources',
        showProjectDiscussion: 'Afficher la discussion du projet',
        showAssetDiscussion: 'Afficher la discussion de la ressource',
        reply: 'Répondre',
        showReplies: 'Afficher les réponses',
        hideReplies: 'Masquer les réponses',
        showAllReplies: 'Afficher toutes les réponses',
        hideAllReplies: 'Masquer toutes les réponses',
        send: 'Envoyez',
        commentActions: 'Actions de commentaire',
        confirmDeletingComment: 'Confirmer la suppression du commentaire',
        deletingCommentPrompt: 'Êtes-vous sûr de vouloir supprimer ce commentaire ?',
        markComplete: 'Marquer comme complet',
        markIncomplete: 'Marquer comme incomplet',
        hideForExternal: 'Masquer pour les utilisateurs externes',
        unhideForExternal: 'Afficher pour les utilisateurs externes',
        download: 'Télécharger',
        attachFile: 'Joindre un fichier',
        deleteAttachment: 'Supprimer la pièce jointe',
        confirmDeletingAttachment: 'Confirmer la suppression de la pièce jointe',
        deletingAttachmentPrompt: 'Êtes-vous sûr de vouloir supprimer cette pièce jointe ?',
        attachmentUploadError: 'Une erreur est survenue lors du téléchargement. Veuillez réessayer ultérieurement.',
        close: 'Fermer',
        save: 'Enregistrer',
        revealInViewer: 'Révéler dans le visualiseur',
        view: 'Voir',
        fileInfo: 'Informations sur le fichier',
        addReaction: 'Ajouter une réaction',
        // #endregion

        // #region Compare Modes
        selectCompareAsset: 'Sélectionner la ressource à comparer',
        generatingCompareResult: 'Génération du résultat de comparaison. Cela peut prendre un certain temps...',
        sideBySide: 'Cote à cote',
        withDifference: 'Côte à côte avec différence',
        fader: 'Fader',
        diff: 'La différence',
        toggle: 'Basculer',
        // #endregion

        // #region Extra Tools

        toolBox: 'Boîte à outils',
        ruler: 'Règle',
        rulerUnits: 'Unités de règle',
        setRulerStartingPoint: 'Définir le point de départ de la règle',
        rulerMultilineHint: 'Maintenez la touche **Ctrl** enfoncée pour dessiner plusieurs lignes',
        distance: 'Distance',
        inches: 'Pouces',
        centimeters: 'Centimètres',
        millimeters: 'Millimètres',
        eyedropper: 'Pipette',
        colorModel: 'Modèle de couleur',
        colorValue: 'Valeur de couleur',
        pickColorFromAsset: 'Choisir la couleur à partir de la ressource',
        generateColors: 'Générer un schéma de couleurs',
        processing: 'Traitement en cours...',
        barcodeScanner: 'Lecteur de codes-barres',
        barcodeScannerPrompt: 'Sélectionnez la région contenant un code-barres',
        barcodePanningPrompt: 'Faites glisser l\'image pour trouver un code-barres',
        toPanMode: 'Basculer en mode déplacement de l\'image',
        toScanMode: 'Basculer en mode de numérisation d\'un autre code-barres',
        readingBarcode: 'Lecture du code-barres...',
        barcodeType: 'Type de code-barres:',
        text: 'Texte:',
        spellchecker: 'Correcteur orthographique',
        spellcheckerServiceError: 'Nous n\'avons pas pu nous connecter au service de correction orthographique. Veuillez réessayer ultérieurement.',
        retry: 'Réessayer',
        'Barcode not recognized.': 'Nous n\'avons pas pu reconnaître le code-barres dans la région sélectionnée. Essayez de zoomer sur l\'image ou de sélectionner avec plus de précision',
        barcodeServerError: 'Une erreur est survenue lors du traitement du code-barres. Veuillez réessayer ultérieurement.',
        markWordAsCorrect: 'Marquer le mot comme correct',
        markWordAsIncorrect: 'Marquer le mot comme incorrect',
        wordMarkedAsCorrect: 'Le mot "{0}" a été marqué comme correct',
        wordMarkedAsIncorrect: 'Le mot "{0}" a été marqué comme incorrect',
        talkToAi: 'Parlez à l\'IA',
        enterYourQuestion: 'Entrez votre question',
        aiAssistantError: 'Une erreur s\'est produite lors du traitement de votre demande. Veuillez réessayer ultérieurement.',
        // #endregion

        // #region Alerts
        taskWasClosed: 'La tâche a été fermée',
        assetFilesCorrupted: 'Certains des fichiers de ressources sont corrompus. Veuillez contacter votre administrateur.',
        // #endregion

        // #region Share Dialog
        createExternalReview: 'Créer une révision externe',
        Name: 'Nom',
        email: 'Adresses e-mail séparées par des virgules',
        emailError: 'Les adresses e-mail ne sont pas valides',
        addressBookRecord: 'Enregistrement du carnet d\'adresses',
        editAddressBookRecord: 'Modifier l\'enregistrement du carnet d\'adresses',
        saveAddress: 'Guardar dirección',
        remove: 'Supprimer',
        advancedOptions: 'Options avancées',
        hideAdvancedOptions: 'Cacher',
        allowAssetDownload: 'Autoriser le téléchargement des éléments',
        allowSubscriptions: "Permettre de s'abonner aux changements de fichier",
        simpleMode: 'Mode simple',
        subject: 'Sujet',
        password: 'Mot de passe',
        comment: 'Le commentaire',
        checklist: 'Liste de contrôle',
        selectChecklist: 'Sélectionner une liste de contrôle',
        configure: 'Configurer',
        getShortlink: 'Obtenir un lien court',
        checklistConfigurePrompt: 'Sélectionnez les éléments à inclure dans la liste de contrôle de révision',
        customized: '(personnalisé)',
        apply: 'Appliquer',
        emailsWereSent: 'Les e-mails de demande ont été envoyés avec succès',
        shortlinkCopied: 'Le lien court a été copié dans le presse-papiers',
        addressBook: 'Carnet d\'adresses',
        selectContacts: 'Sélectionnez des contacts à inclure dans votre liste de diffusion',
        selectContactsAndGroups: 'Sélectionnez des contacts et des groupes à inclure dans votre liste de diffusion',
        contacts: 'Contacts',
        groups: 'Groupes',
        newConact: 'Nouveau contact',
        EditGroup: 'Modifier le groupe',
        selectiveApprovalMode: 'Mode de révision sélective',
        template: 'Modèle',
        // #endregion

        // #region External User Intro Dialog
        introduceYourself: 'Veuillez vous présenter',
        enterYourName: 'Entrez votre nom',
        confirm: 'Confirmer',
        // #endregion

        copyright: 'Copyright © 2018-2025 by Approval Studio',
        dueDate: 'Date d`échéance',

        thisLinkIsWrong: 'Ce lien est incorrect ou obsolète!',
        goHome: 'Retour à la page d`accueil',
        approvalStudio: 'Approval Studio',
        enterYourComment: 'Entrez votre commentaire',
        failedGenerateColorScheme: 'Échec de la génération du schéma de couleurs',
        failedGetPixelInfo: 'Échec de l`obtention des informations sur le pixel',
        exifInfo: 'EXIF Info',

        // #region Tour
        takeTour: 'Faire un tour',
        tourInternalIntro: 'Bienvenue dans l\'outil de révision Approval Studio ! Faites un court tour pour voir comment cela fonctionne',
        tourExternalIntro: 'Vous avez été invité à examiner un fichier. Faites un court tour pour voir comment cela fonctionne',
        tourZoom: 'Vous pouvez zoomer, dézoomer ou faire pivoter l\'image pour vérifier chaque détail',
        tourApprove: 'Cliquez sur le bouton "Approuver" si vous êtes satisfait et qu\'aucune modification n\'est nécessaire',
        tourAnnotate: 'Si quelque chose doit être modifié ou commenté, sélectionnez un outil d\'annotation et dessinez sur la zone de l\'image que vous souhaitez annoter.',
        tourRequestChanges: 'Cliquez sur le bouton "Demander des modifications" pour demander des changements et nous transmettre vos commentaires',
        tourCompare: 'Choisissez un des modes de comparaison pour voir les différences entre la version actuelle et précédente du fichier',
        tourTools: 'Utilisez nos outils supplémentaires pour mesurer des distances, choisir des couleurs, scanner des codes-barres, et plus encore',
        tourProjectDiscussion: 'Utilisez le panneau de Discussion du Projet pour discuter du projet avec votre équipe',
        tourDownload: 'Téléchargez le fichier si vous souhaitez le conserver sur votre appareil',
        tourShare: 'Partagez le fichier avec vos collègues ou clients par e-mail ou lien partagé',
        // #endregion

        featureDisabledInDemoMode: 'Cette fonctionnalité est désactivée en mode démo',
    },
    pl: {
        // #region Languages
        english: 'English',
        polish: 'Polski',
        ukrainian: 'Українська',
        german: 'Deutsch',
        french: 'Français',
        spanish: 'Español',
        // #endregion

        // #region Appbar
        projectName: 'Projekt:',
        downloadAsset: 'Pobierz zasób',
        share: 'Udostępnij',
        switchToDarkMode: 'Przełącz na tryb ciemny',
        switchToLightMode: 'Przełącz na tryb jasny',
        subscribeToAsset: 'Subskrybuj plik',
        getNotifications: 'Otrzymuj powiadomienia e-mail, gdy',
        commentUpdates: 'Ktoś doda nowe komentarze do tego pliku',
        fileVersionsUpdates: 'Ktoś prześle nową wersję tego pliku',
        // #endregion

        // #region Viewer
        generatingServiceFiles: 'Generowanie plików serwisowych...',
        // #endregion

        // #region Viewer Tools
        annotate: 'Dodaj adnotację',
        arrow: 'Strzałka',
        point: 'Kropka',
        square: 'Pole',
        circle: 'Koło',
        free: 'Wolny kształt',
        pickColor: 'Wybierz kolor',
        pickBackground: 'Wybierz tło',
        showAssets: 'Pokaż zasoby',
        hideAssetsPanel: 'Ukryj panel zasobów',
        hidePanel: 'Ukryj panel narzędzi',
        showToolsPanel: 'Pokaż panel narzędzi',
        showAnnotations: 'Pokaż adnotacje',
        hideAnnotations: 'Ukryj adnotacje',

        compareTools: 'Narzędzia porównywania',
        quitCompareMode: 'Zakończ tryb porównywania',
        fitToViewport: 'Dopasuj do widoku',
        zoomIn: 'Powiększ',
        zoomOut: 'Pomniejsz',
        rotateLeft: 'Obrót w lewo',
        rotateRight: 'Obróć w prawo',

        // #endregion

        // #region Approval
        confirmApprove: 'Zatwierdź',
        confirmApprovePrompt: 'Potwierdź, że chcesz zatwierdzić',
        approve: 'Zatwierdź',
        approveSelected: 'Zatwierdź wybrane',
        confirmApproveWithChanges: 'Zatwierdzić ze zmianami',
        approveWithChangesSelected: 'Zatwierdź z wybranymi zmianami',
        confirmApproveWithChangesPrompt: 'Proszę potwierdzenia, że chcesz zarwierdzić zmiany',
        approveWithChanges: 'Zatwierdzić ze zmianami',
        confirmRequestChanges: 'Poproś o zmiany',
        confirmRequestChangesPrompt: 'Potwierdź, że chcesz poprosić o zmiany',
        requestChanges: 'Poproś o zmiany',
        checklistRequiredPrompt: 'Proszę sprawdź wszystkie elementy na liście kontrolnej poniżej',
        commentsAreRequired: 'Komentarze są wymagane',
        youHaveToCommentAssetBeforeRequestingChanges: 'Musisz wprowadzić co najmniej jeden komentarz przed żądaniem zmian',
        ok: 'OK',
        cancel: 'Anuluj',
        // #endregion

        // #region Textbox Menu
        annotationActions: 'Akcje adnotacji',
        editText: 'Edytuj tekst',
        revealInDiscussionPanel: 'Pokaż w panelu dyskusji',
        cancelEditing: 'Anuluj edycję',
        addFigure: 'Dodaj figurę',
        removeSelectedFigure: 'Usuń wybraną figurę',
        changeColor: 'Zmień kolor',
        delete: 'Usunąć',
        confirmDeletingAnnotation: 'Potwierdź usunięcie adnotacji',
        deletingAnnotationPrompt: 'Czy na pewno chcesz usunąć tę adnotację?',
        // #endregion

        // #region Thumbnails Panel
        version: 'version',
        loadPdfLayers: 'Załaduj warstwy PDF',
        showPdfLayers: 'Pokaż warstwy PDF',
        hidePdfLayers: 'Ukryj warstwy PDF',
        loadingPdfLayers: 'Ładowanie warstw PDF...',
        annotationsOnPage: 'Adnotacje na stronie',
        selectAll: 'Zaznacz wszystko',
        // #endregion

        // #region Discussion Panel
        projectDiscussion: 'Dyskusja odnośnie projektu',
        assetDiscussion: 'Adnotacje zasobu',
        showProjectDiscussion: 'Pokaż dyskusję projektu',
        showAssetDiscussion: 'Pokaż dyskusję zasobu',
        reply: 'Odpowiedzieć',
        showReplies: 'Pokaż odpowiedzi',
        hideReplies: 'Ukryj odpowiedzi',
        showAllReplies: 'Pokaż wszystkie odpowiedzi',
        hideAllReplies: 'Ukryj wszystkie odpowiedzi',
        send: 'Wysłać',
        commentActions: 'Akcje komentarza',
        confirmDeletingComment: 'Potwierdź usunięcie komentarza',
        deletingCommentPrompt: 'Czy na pewno chcesz usunąć ten komentarz?',
        markComplete: 'Oznacz jako zakończone',
        markIncomplete: 'Oznacz jako nie zakończone',
        hideForExternal: 'Ukryj dla użytkowników zewnętrznych',
        unhideForExternal: 'Pokaż dla użytkowników zewnętrznych',
        download: 'Pobierz',
        attachFile: 'Załącz plik',
        deleteAttachment: 'Usuń załącznik',
        confirmDeletingAttachment: 'Potwierdź usunięcie załącznika',
        deletingAttachmentPrompt: 'Czy na pewno chcesz usunąć ten załącznik?',
        attachmentUploadError: 'Wystąpił błąd podczas przesyłania. Proszę spróbować ponownie później.',
        close: 'Zamknij',
        save: 'Zapisz',
        revealInViewer: 'Pokaż w przeglądarce',
        view: 'Widok',
        fileInfo: 'Informacje o pliku',
        addReaction: 'Dodaj reakcję',
        // #endregion

        // #region Compare Modes
        selectCompareAsset: 'Wybierz zasób do porównania',
        generatingCompareResult: 'Generowanie wyniku porównania. To może chwilę potrwać...',
        sideBySide: 'Obok siebie',
        withDifference: 'Obok z różnicą',
        fader: 'Fader',
        diff: 'Różnica',
        toggle: 'Przełączać',
        // #endregion

        // #region Extra Tools

        toolBox: 'Narzędzia',
        ruler: 'Linijka',
        rulerUnits: 'Jednostki linijki',
        setRulerStartingPoint: 'Ustaw punkt początkowy linijki',
        rulerMultilineHint: 'Przytrzymaj klawisz **Ctrl**, aby rysować wieloliniowo',
        distance: 'Odległość',
        inches: 'Cale',
        centimeters: 'Centymetry',
        millimeters: 'Milimetry',
        eyedropper: 'Pipeta',
        colorModel: 'Model koloru',
        colorValue: 'Wartość koloru',
        pickColorFromAsset: 'Wybierz kolor z zasobu',
        generateColors: 'Generuj schemat kolorów',
        processing: 'Przetwarzanie...',
        barcodeScanner: 'Skaner kodów kreskowych',
        barcodeScannerPrompt: 'Wybierz region zawierający kod kreskowy',
        barcodePanningPrompt: 'Przeciągnij obraz, aby znaleźć kod kreskowy',
        toPanMode: 'Przełącz się w tryb przesuwania obrazu',
        toScanMode: 'Przełącz się w tryb skanowania kolejnego kodu kreskowego',
        readingBarcode: 'Odczytywanie kodu kreskowego...',
        barcodeType: 'Typ kodu kreskowego:',
        text: 'Tekst:',
        spellchecker: 'Sprawdzanie pisowni',
        spellcheckerServiceError: 'Nie udało się połączyć z usługą sprawdzania pisowni. Proszę spróbować ponownie później.',
        retry: 'Ponów',
        'Barcode not recognized.': 'Nie udało się rozpoznać kodu kreskowego w wybranym regionie. Spróbuj przybliżyć obraz lub wybrać dokładniej',
        barcodeServerError: 'Wystąpił błąd podczas przetwarzania kodu kreskowego. Proszę spróbować ponownie później.',
        markWordAsCorrect: 'Oznacz słowo jako poprawne',
        markWordAsIncorrect: 'Oznacz słowo jako niepoprawne',
        wordMarkedAsCorrect: 'Słowo "{0}" zostało oznaczone jako poprawne',
        wordMarkedAsIncorrect: 'Słowo "{0}" zostało oznaczone jako niepoprawne',
        talkToAi: 'Porozmawiaj z AI',
        enterYourQuestion: 'Wprowadź swoje pytanie',
        aiAssistantError: 'Wystąpił błąd podczas przetwarzania Twojego żądania. Spróbuj ponownie później.',
        // #endregion

        // #region Alerts
        taskWasClosed: 'Завдання було закрито',
        assetFilesCorrupted: 'Деякі файли засобів повреждені. Будь ласка, зверніться до адміністратора.',
        // #endregion

        // #region Share Dialog
        createExternalReview: 'Utwórz przegląd zewnętrzny',
        Name: 'Imię',
        email: 'Adresy e-mail oddzielone przecinkami',
        emailError: 'Niepoprawne adresy e-mail',
        addressBookRecord: 'Rekord w książce adresowej',
        editAddressBookRecord: 'Edytuj rekord w książce adresowej',
        saveAddress: 'Zapisz adres',
        remove: 'Usuń',
        advancedOptions: 'Opcje zaawansowane',
        hideAdvancedOptions: 'Ukryć',
        allowAssetDownload: 'Zezwalaj na pobieranie zasobów',
        allowSubscriptions: 'Zezwól na subskrypcję zmian pliku',
        simpleMode: 'Tryb prosty',
        subject: 'Temat',
        password: 'Hasło',
        comment: 'Zostaw komentarz',
        checklist: 'Lista kontrolna',
        selectChecklist: 'Wybierz listę kontrolną',
        configure: 'Skonfiguruj',
        getShortlink: 'Pobierz krótki link',
        checklistConfigurePrompt: 'Wybierz elementy do uwzględnienia w liście kontrolnej przeglądu',
        customized: '(dostosowane)',
        apply: 'Zastosować',
        emailsWereSent: 'E-maile z prośbą zostały wysłane pomyślnie',
        shortlinkCopied: 'Krótki link został skopiowany do schowka',
        addressBook: 'Książka adresowa',
        selectContacts: 'Wybierz kontakty do dodania do swojej listy mailingowej',
        selectContactsAndGroups: 'Wybierz kontakty i grupy do dodania do swojej listy mailingowej',
        contacts: 'Kontakty',
        groups: 'Grupy',
        newConact: 'Nowy kontakt',
        EditGroup: 'Edytuj grupę',
        selectiveApprovalMode: 'Selektywny tryb zatwierdzania',
        template: 'Szablon',
        // #endregion

        // #region External User Intro Dialog
        introduceYourself: 'Proszę się przedstawić',
        enterYourName: 'Wprowadź swoje imię',
        confirm: 'Potwierdź',
        // #endregion

        copyright: 'Copyright © 2018-2025 by Approval Studio',
        dueDate: 'Termin',

        thisLinkIsWrong: 'To łącze jest błędne lub przestarzałe!',
        goHome: 'Do głównej',
        approvalStudio: 'Approval Studio',
        enterYourComment: 'Wprowadź swój komentarz',
        failedGenerateColorScheme: 'Nie udało się wygenerować schematu kolorów',
        failedGetPixelInfo: 'Nie udało się uzyskać informacji o pikselu',
        exifInfo: 'EXIF Info',

        // #region Tour
        takeTour: 'Rozpocznij wycieczkę',
        tourInternalIntro: 'Witamy w narzędziu przeglądu Approval Studio! Przejdź krótką wycieczkę, aby zobaczyć, jak to działa',
        tourExternalIntro: 'Zostałeś zaproszony do przeglądu pliku. Przejdź krótką wycieczkę, aby zobaczyć, jak to działa',
        tourZoom: 'Możesz powiększać, pomniejszać lub obracać obraz, aby upewnić się, że wszystko sprawdzono',
        tourApprove: 'Kliknij przycisk "Zatwierdź", jeśli jesteś zadowolony i nic nie wymaga zmiany',
        tourAnnotate: 'Jeśli coś wymaga zmiany lub komentarza, wybierz narzędzie do adnotacji i zaznacz obszar obrazu, który chcesz skomentować.',
        tourRequestChanges: 'Kliknij przycisk "Poproś o zmiany", aby poprosić o zmiany i przesłać nam swoje uwagi',
        tourCompare: 'Wybierz jeden z trybów porównywania, aby zobaczyć różnice między bieżącą a poprzednią wersją pliku',
        tourTools: 'Skorzystaj z naszych dodatkowych narzędzi do mierzenia odległości, wybierania kolorów, skanowania kodów kreskowych i więcej',
        tourProjectDiscussion: 'Użyj panelu dyskusji projektu, aby omówić projekt z zespołem',
        tourDownload: 'Pobierz plik, jeśli potrzebujesz go na swoim urządzeniu',
        tourShare: 'Udostępnij plik swoim kolegom lub klientom za pomocą e-maila lub linku udostępnianego',
        // #endregion

        featureDisabledInDemoMode: 'Ta funkcja jest wyłączona w trybie demonstracyjnym',
    },
    ua: {
        // #region Languages
        english: 'English',
        polish: 'Polski',
        ukrainian: 'Українська',
        german: 'Deutsch',
        french: 'Français',
        spanish: 'Español',
        // #endregion

        // #region Appbar
        projectName: 'Проект:',
        downloadAsset: 'Скачати файл',
        share: 'Поділитись',
        switchToDarkMode: 'Перейти до темного режиму',
        switchToLightMode: 'Перейти до світлого режиму',
        subscribeToAsset: 'Підписатися на файл',
        getNotifications: 'Отримувати електронні повідомлення, коли',
        commentUpdates: 'Хтось додає нові коментарі до цього файлу',
        fileVersionsUpdates: 'Хтось завантажує нову версію цього файлу',
        // #endregion

        // #region Viewer
        generatingServiceFiles: 'Генеруються службові файли...',
        // #endregion

        // #region Viewer Tools
        annotate: 'Додати анотацію',
        arrow: 'Стрілка',
        point: 'Точка',
        square: 'Квадрат',
        circle: 'Коло',
        free: 'Вільна форма',
        pickColor: 'Вибрати колір',
        pickBackground: 'Вибрати фон',
        showAssets: 'Показати файли',
        hideAssetsPanel: 'Приховати панель файлів',
        hidePanel: 'Приховати панель інструментів',
        showToolsPanel: 'Показати панель інструментів',
        showAnnotations: 'Показати анотації',
        hideAnnotations: 'Приховати анотації',

        compareTools: 'Інструменти порівняння',
        quitCompareMode: 'Вийти з режиму порівняння',
        fitToViewport: 'Вписати в видиму область',
        zoomIn: 'Збільшити',
        zoomOut: 'Зменшити',
        rotateLeft: 'Повернути ліворуч',
        rotateRight: 'Повернути праворуч',

        // #endregion

        // #region Approval
        confirmApprove: 'Затвердити',
        confirmApprovePrompt: 'Підтвердьте, що хочете схвалити',
        approve: 'Затвердити',
        approveSelected: 'Затвердити вибране',
        confirmApproveWithChanges: 'Затвердити зі змінами',
        approveWithChangesSelected: 'Затвердити вибране зі змінами',
        confirmApproveWithChangesPrompt: 'Будь ласка, підтвердіть, що хочете затвердити зі змінами',
        approveWithChanges: 'Затвердити зі змінами',
        confirmRequestChanges: 'Замовити зміни',
        confirmRequestChangesPrompt: 'Підтвердьте, що хочете замовити змiни',
        requestChanges: 'Замовити зміни',
        checklistRequiredPrompt: 'Будь ласка, перевірте всі пункти переліку нижче',
        commentsAreRequired: 'Коментарі обов\'язкові',
        youHaveToCommentAssetBeforeRequestingChanges: 'Перед вимогою змін потрібно ввести хоча б один коментар',
        ok: 'OK',
        cancel: 'Відмінити',
        // #endregion

        // #region Textbox Menu
        annotationActions: 'Дії з анотаціями',
        editText: 'Редагувати текст',
        revealInDiscussionPanel: 'Показати в панелі обговорення',
        cancelEditing: 'Скасувати редагування',
        addFigure: 'Додати фігуру',
        removeSelectedFigure: 'Видалити вибрану фігуру',
        changeColor: 'Змінити колір',
        delete: 'Видалити',
        confirmDeletingAnnotation: 'Підтвердіть видалення анотації',
        deletingAnnotationPrompt: 'Ви впевнені, що хочете видалити цю анотацію?',
        // #endregion

        // #region Thumbnails Panel
        version: 'версія',
        loadPdfLayers: 'Завантажити PDF шари',
        showPdfLayers: 'Показати шари PDF',
        hidePdfLayers: 'Приховати шари PDF',
        loadingPdfLayers: 'Завантаження PDF шарів...',
        annotationsOnPage: 'Анотації на сторінці',
        selectAll: 'Вибрати все',
        // #endregion

        // #region Discussion Panel
        projectDiscussion: 'Обговорення проекту',
        assetDiscussion: 'Анотації до файлу',
        showProjectDiscussion: 'Показати обговорення проекту',
        showAssetDiscussion: 'Показати обговорення файлу',
        reply: 'Відповісти',
        showReplies: 'Показати відповіді',
        hideReplies: 'Приховати відповіді',
        showAllReplies: 'Показати всі відповіді',
        hideAllReplies: 'Приховати всі відповіді',
        send: 'Надіслати',
        commentActions: 'Дії з коментарями',
        confirmDeletingComment: 'Підтвердіть видалення коментаря',
        deletingCommentPrompt: 'Ви впевнені, що хочете видалити цей коментар?',
        markComplete: 'Помітити виконаним',
        markIncomplete: 'Помітити невиконаним',
        hideForExternal: 'Приховати для зовнішніх користувачів',
        unhideForExternal: 'Показати для зовнішніх користувачів',
        download: 'Скачати',
        attachFile: 'Прикріпити файл',
        deleteAttachment: 'Видалити вкладення',
        confirmDeletingAttachment: 'Підтвердіть видалення вкладення',
        deletingAttachmentPrompt: 'Ви впевнені, що хочете видалити це вкладення?',
        attachmentUploadError: 'Виникла помилка під час завантаження. Спробуйте ще раз пізніше.',
        close: 'Закрити',
        save: 'Зберегти',
        revealInViewer: 'Показати в переглядачі',
        view: 'Перегляд',
        fileInfo: 'Інформація про файл',
        addReaction: 'Додати реакцію',
        // #endregion

        // #region Compare Modes
        selectCompareAsset: 'Виберіть ресурс для порівняння',
        generatingCompareResult: 'Створення результату порівняння. Це може зайняти деякий час...',
        sideBySide: 'Поруч',
        withDifference: 'Поруч з різницею',
        fader: 'Фейдер',
        diff: 'Різниця',
        toggle: 'Переключати',
        // #endregion

        // #region Extra Tools

        toolBox: 'Набір інструментів',
        ruler: 'Лінійка',
        rulerUnits: 'Одиниці вимірювання лінійки',
        setRulerStartingPoint: 'Вкажіть початкову точку лінійки',
        rulerMultilineHint: 'Утримуйте **Ctrl**, щоб намалювати багаторядкову лінію',
        distance: 'Відстань',
        inches: 'Дюйми',
        centimeters: 'Сантиметри',
        millimeters: 'Міліметри',
        eyedropper: 'Піпетка',
        colorModel: 'Кольорова модель',
        colorValue: 'Значення кольору',
        pickColorFromAsset: 'Вибрати колір з файлу',
        generateColors: 'Створити колірну схему',
        processing: 'Обробка...',
        barcodeScanner: 'Сканер штрих-кодів',
        barcodeScannerPrompt: 'Виберіть область, яка містить штрих-код',
        barcodePanningPrompt: 'Перетягніть зображення, щоб знайти штрих-код',
        toPanMode: 'Переключитися на режим переміщення зображення',
        toScanMode: 'Переключитися на режим сканування іншого штрих-коду',
        readingBarcode: 'Зчитування штрихкоду...',
        barcodeType: 'Тип штрих-кодів:',
        text: 'Текст:',
        spellchecker: 'Перевірка орфографії',
        spellcheckerServiceError: 'Не вдалося підключитися до служби перевірки правопису. Будь ласка, спробуйте ще раз пізніше.',
        retry: 'Повторити спробу',
        'Barcode not recognized.': 'Ми не змогли визначити штрих-код у вибраній області. Спробуйте збільшити зображення або вибрати точніше',
        barcodeServerError: 'Виникла помилка під час обробки штрих-коду. Будь ласка, спробуйте ще раз пізніше.',
        markWordAsCorrect: 'Відмітити слово як правильне',
        markWordAsIncorrect: 'Відмітити слово як неправильне',
        wordMarkedAsCorrect: 'Слово "{0}" відзначено як правильне',
        wordMarkedAsIncorrect: 'Слово "{0}" відзначено як неправильне',
        talkToAi: 'Поговорити з ШІ',
        enterYourQuestion: 'Введіть своє питання',
        aiAssistantError: 'Під час обробки вашого запиту сталася помилка. Будь ласка, спробуйте пізніше.',

        // #endregion

        // #region Alerts
        taskWasClosed: 'Завдання було закрито',
        assetFilesCorrupted: 'Деякі файли пошкоджені. Будь ласка, зверніться до адміністратора.',
        // #endregion

        // #region Share Dialog
        createExternalReview: 'Надіслати на розгляд',
        Name: 'Ім\'я',
        email: 'Адреси електронної пошти (через кому)',
        emailError: 'Некоректні адреси електронної пошти',
        addressBookRecord: 'Запис у адресній книзі',
        editAddressBookRecord: 'Редагувати запис у адресній книзі',
        saveAddress: 'Зберегти адресу',
        remove: 'Видалити',
        advancedOptions: 'Розширені параметри',
        hideAdvancedOptions: 'Сховати',
        allowAssetDownload: 'Дозволити скачати файл',
        allowSubscriptions: 'Дозволити підписку на зміни файлу',
        simpleMode: 'Спрощений режим',
        subject: 'Тема',
        password: 'Пароль',
        comment: 'Коментар',
        checklist: 'Чекліст',
        selectChecklist: 'Обрати чекліст',
        configure: 'Налаштувати',
        getShortlink: 'Отримати коротке посилання',
        checklistConfigurePrompt: 'Виберіть елементи для включення до перевіркового переліку',
        customized: '(налаштовано)',
        apply: 'Застосувати',
        emailsWereSent: 'Запит на електронну пошту надіслано успішно',
        shortlinkCopied: 'Коротке посилання скопійовано в буфер обміну',
        addressBook: 'Адресна книга',
        selectContacts: 'Виберіть контакти для включення до вашого списку розсилки',
        selectContactsAndGroups: 'Виберіть контакти та групи для включення до вашого списку розсилки',
        contacts: 'Контакти',
        groups: 'Групи',
        newConact: 'Новий контакт',
        EditGroup: 'Редагувати групу',
        selectiveApprovalMode: 'Вибірковий метод затвердження',
        template: 'Шаблон',
        // #endregion

        // #region External User Intro Dialog
        introduceYourself: 'Будь ласка, представтеся',
        enterYourName: 'Введіть ваше ім\'я',
        confirm: 'Підтвердити',
        // #endregion

        copyright: 'Copyright © 2018-2025 by Approval Studio',
        dueDate: 'Завершити до',

        thisLinkIsWrong: 'Це посилання невірне або застаріле!',
        goHome: 'На головну',
        approvalStudio: 'Approval Studio',
        enterYourComment: 'Введіть свій коментар',
        failedGenerateColorScheme: 'Не вдалося створити схему кольорів',
        failedGetPixelInfo: 'Не вдалося отримати інформацію про піксель',
        exifInfo: 'EXIF данні',

        // #region Tour
        takeTour: 'Розпочати тур',
        tourInternalIntro: 'Ласкаво просимо до інструменту рецензування Approval Studio! Пройдіть короткий тур, щоб побачити, як це працює',
        tourExternalIntro: 'Вас запросили розлянути файл. Пройдіть короткий тур, щоб дізнатися, як це працює',
        tourZoom: 'Ви можете збільшувати, зменшувати або повертати зображення, щоб переконатися, що перевірили все',
        tourApprove: 'Натисніть кнопку "Затвердити", якщо вас усе влаштовує і нічого не потрібно змінювати',
        tourAnnotate: 'Якщо потрібно щось змінити або прокоментувати, виберіть інструмент для анотацій і намалюйте область на зображенні, яку хочете прокоментувати.',
        tourRequestChanges: 'Натисніть кнопку "Замовити зміни", щоб запросити зміни і надіслати нам ваші відгуки',
        tourCompare: 'Виберіть один із режимів порівняння, щоб побачити відмінності між поточною та попередньою версією файлу',
        tourTools: 'Скористайтеся нашими додатковими інструментами для вимірювання відстаней, вибору кольорів, сканування штрих-кодів тощо',
        tourProjectDiscussion: 'Використовуйте панель обговорення проекту для спілкування з вашою командою',
        tourDownload: 'Завантажте файл, якщо потрібно зберегти його на своєму пристрої',
        tourShare: 'Поділіться файлом із колегами або клієнтами за допомогою електронної пошти або посилання для спільного доступу',
        // #endregion

        featureDisabledInDemoMode: 'Ця функція вимкнена в демо-режимі',
    },
});

const localeDict = {
    en: 'en-US',
    de: 'de-DE',
    fr: 'fr-FR',
    es: 'es-ES',
    pl: 'pl-PL',
    he: 'he-IL',
    ua: 'uk-UA',
};

export function getLocalization() {
    return strings;
}

export function setLocalization(ln) {
    strings.setLanguage(ln);
}

export function getLocaleCode(currentLang) { return localeDict[currentLang] ?? localeDict.en; }
