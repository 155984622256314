import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { FlexRow, GlobalStyle } from '../../styles';
import { LinkBreak } from '@phosphor-icons/react';
import logo from '../../assets/img/appstudio-logo.png';
import getWrongLinkCubit from './WrongLinkCubit';

// #region Styles

const Wrapper = styled.div`
    color: var(--contrast-text);
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    background-image: var(--background-image);
    background-size: cover;
`;

const Header = styled(FlexRow).attrs(() => ({
    className: 'glass-surface',
}))`
    z-index: 10;
    padding: 4px 16px;
    border-radius: 0px 0px 10px 10px!important;
    -webkit-box-align: center;
    background: var(--glass-tone-cold);
`;

const Logo = styled.div`
    background-color: var(--popover-background);
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 20px;
        height: 20px;
    }
`;

const Brand = styled.div`
    font-size: 16px;
    margin-left: 12px;
    font-family: 'Montserrat Medium';
`;

const Content = styled(FlexRow)`
    -webkit-box-flex: 1;
    flex-grow: 1;
    position: relative;
    height: 100%;
    -webkit-box-pack: center;
    justify-content: center;
`;

const Row = styled(FlexRow)`
    margin-bottom: 24px;
`;

const MessageFrame = styled.div.attrs(() => ({
    className: 'glass-surface',
}))`
    padding: 48px;
    padding-bottom: 24px;
    margin: auto;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
`;

const BrokenLinkIcon = styled(LinkBreak).attrs(() => ({
    size: 36,
}))`
    margin-right: 24px;
`;

const ButtonLink = styled.button`
    font-family: 'Montserrat', sans-serif;
    user-select: none;
    cursor: pointer;
    text-transform: uppercase;
    outline: none;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: var(--button-contrast-color);
    border-radius: 10px;
    padding: 8px 12px;
    margin: 0px 1px 1px 0px;
    border: none;
    background-color: var(--primary-color);
    box-shadow: var(--glass-shadow);

    :hover {
        opacity: 0.8;
        background-color: var(--primary-color);
    }

    :active {
        outline: none;
        margin: 1px 0px 0px 1px;
        box-shadow: none;
    }
`;

const Footer = styled(FlexRow).attrs(() => ({
    className: 'glass-surface-reflected',
}))`
    z-index: 10;
    height: 32px;
    bottom: 0px;
    border-radius: 10px 10px 0px 0px!important;
    padding: 4px 18px;
    -webkit-box-align: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 10px;
    background: var(--glass-tone-cold);
`;

// #endregion

const wrongLink = getWrongLinkCubit();
wrongLink.fetchHomeUrl();

export default observer(function WrongLinkPage() {
    const ThemeProvider = GlobalStyle;
    return (
        <>
            <ThemeProvider theme='Light' />
            <Wrapper>
                <Header>
                    <Logo>
                        <img src={logo} />
                    </Logo>
                    <Brand>Approval Studio</Brand>
                </Header>
                <Content>
                    <MessageFrame>
                        <Row>
                            <BrokenLinkIcon /><span>This link is wrong or outdated!</span>
                        </Row>
                        <ButtonLink onClick={() => { location.href = wrongLink.homeUrl; }}>Go home</ButtonLink>
                    </MessageFrame>
                </Content>
                <Footer>
                    Copyright © 2018–2025 by Approval Studio
                </Footer>
            </Wrapper>
        </>
    );
});
